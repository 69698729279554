<template>
  <div>
    <b-modal id="modal-agregar-factura" title="Agregar Factura" ok-title="Guardar" cancel-title="Cancelar" size="xl"
      no-close-on-esc no-close-on-backdrop hide-header-close headerBgVariant="dark" headerTextVariant="light"
      bodyBgVariant="light" bodyTextVariant="dark" footerBgVariant="warning" footerTextVariant="dark" hide-footer>
      <b-form ref="form" @submit.stop.prevent="onSubmit" @keydown.enter="$event.preventDefault()" novalidate>
        <b-container class="bv-example-row mb-3" fluid>
          <b-row align-h="start">
            <b-col cols="6">
              <b-input-group size="sm">
                <b-form-input v-model="pedidoModel" @keyup.enter="buscarPedido(pedidoModel, consecutivoPedido)"
                  placeholder="Numero pedido">
                </b-form-input>
                <b-form-input v-model="consecutivoPedido" @keyup.enter="buscarPedido(pedidoModel, consecutivoPedido)"
                  placeholder="Consecutivo">
                </b-form-input>
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="buscarPedido(pedidoModel, consecutivoPedido)">Buscar
                    Pedido
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="6">
              <b-input-group size="sm">
                <b-form-select v-model="facturaModel" :options="listaFacturasGuardadas" size="sm"></b-form-select>
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="cargarFactura(facturaModel)">Cargar
                    Factura</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="my-3">
            <b-col cols="4">
              <p class="h5">Agregar Productos</p>
            </b-col>
            <b-col cols="4">

            </b-col>
            <b-col cols="4">
              <b-input-group prepend="Cliente:">
                <b-form-input v-model="pedidoClienteRS" readonly></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-form-row>
            <b-col cols="4">
              <b-input-group prepend="" class="" size="sm">
                <b-form-input v-model="codigoProducto" placeholder="Código"></b-form-input>
                <b-form-input v-model="subCodigoProducto" placeholder="Subcódigo"></b-form-input>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                    @click="agregarProducto(codigoProducto, subCodigoProducto)">Agregar
                    Producto</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4" class="text-right">
              <b-button variant="primary" size="sm" :disabled="$v.$invalid || hasSubmmited"
                @click="guardarFacturaTemporal">Guardar Factura Temporal</b-button></b-col>
          </b-form-row>
          <!-- HEADER -->
          <b-row>
            <b-col cols="1" class="text-left p-0"></b-col>
            <b-col cols="1" class="text-left p-0 small">Código</b-col>
            <b-col cols="4" class="text-left p-0 small">Descripción</b-col>
            <b-col cols="1" class="text-left p-0 small">P.Unitario</b-col>
            <b-col cols="1" class="text-left p-0 small">C.Pedida</b-col>
            <b-col cols="1" class="text-left p-0 small">C.Surtida</b-col>
            <b-col cols="1" class="text-left p-0 small">SubTotal</b-col>
            <b-col cols="2" class="text-left p-0 small">Total con IVA</b-col>
          </b-row>
          <!-- ROWS -->
          <div v-for="(item, key, index) in pedidoProductos" :key="index">
            <RowProducto :key="index" :id="key" :producto="item" ref="productos" @actualizarForm="actualizarFormTotales"
              @borrarProductoEvent="borrarProducto" />
          </div>

          <hr>
          <p class="h5">Datos de Factura</p>
          <b-form-row>
            <!-- COL IZQ -->
            <b-col cols="6">
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Proveedor">
                    <b-form-select v-model="$v.proveedor.$model" :options="listaProveedores"
                      :state="$v.proveedor.required" size="sm"></b-form-select>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Fecha Emisión">
                    <b-form-datepicker id="emision" v-model="$v.fechaEmision.$model" size="sm"
                      :state="$v.fechaEmision.required" @input="actualizarFechaMinimaVencimiento"></b-form-datepicker>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="N° Factura">
                    <b-form-input size="sm" v-model="$v.factura.$model" :state="$v.factura.required"></b-form-input>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Concepto">
                    <b-form-input size="sm" v-model="$v.concepto.$model" :state="$v.concepto.required"></b-form-input>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Vencimiento">
                    <b-form-datepicker id="vencimiento" v-model="$v.fechaVencimiento.$model" size="sm"
                      :state="$v.fechaVencimiento.required" :min="minDateVencimiento"></b-form-datepicker>
                  </b-form-group>
                </b-col></b-form-row>
            </b-col>
            <!-- COL DER -->
            <b-col cols="6">
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Total Pedido Surtido con IVA">
                    <b-input-group prepend="$" size="sm"><b-form-input size="sm" type="number"
                        v-model="totalSurtidoConIVA" readonly></b-form-input></b-input-group>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Sub Total Factura">
                    <b-input-group prepend="$" size="sm"><b-form-input size="sm" type="number" v-model="subTotal"
                        readonly></b-form-input></b-input-group>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="IVA Factura (16%)">
                    <b-input-group prepend="$" size="sm"><b-form-input size="sm" type="number" v-model="iva"
                        readonly></b-form-input></b-input-group>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="IEPS">
                    <b-input-group prepend="$" size="sm"><b-form-input size="sm" type="number" v-model="ieps"
                        readonly></b-form-input></b-input-group>
                  </b-form-group>
                </b-col></b-form-row>
              <b-form-row align-h="start"><b-col>
                  <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Total Factura">
                    <b-input-group prepend="$" size="sm"><b-form-input size="sm" type="number" v-model="total"
                        readonly></b-form-input></b-input-group>
                  </b-form-group>
                </b-col></b-form-row>
            </b-col>
          </b-form-row>

          <b-row align-h="end">
            <b-col cols="6" class="text-right">
              <div v-if="spinner">
                <div class="clearfix">
                  <b-spinner class="float-right" label="Guardando...espere un momento"
                    style="width: 1.8rem; height: 1.8rem;"></b-spinner>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button-group>
                <b-button class="m-1" variant="info" @click="hideModal()" :disabled="spinner">Cancelar</b-button>
                <b-button class="m-1" variant="primary" @click="guardarFactura"
                  :disabled="$v.$invalid || hasSubmmited">Guardar</b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import api from "../../../utils/api.js";
import RowProducto from './RowProducto.vue';
export default {
  name: 'AgregarFacturas',
  data() {
    return {
      consecutivoPedido: '',
      codigoPedido: '',
      listaFacturasGuardadas: [],
      facturaModel: null,
      pedidoProductos: [],
      proveedor: null,
      codigoProducto: '',
      subCodigoProducto: '',
      pedidoModel: '',
      backorderModel: '',
      pedidoID: null,
      pedidoCliente: null,
      pedidoClienteRS: '',
      pedidoVendedor: null,
      pedidoProveedor: null,
      pedidoConsecutivo: '',
      facturaID: 0,
      fechaEmision: null,
      factura: '',
      concepto: '',
      fechaVencimiento: null,
      total: 0,
      totalSurtidoConIVA: 0,
      subTotal: 0,
      iva: 0,
      ieps: 0,
      facturaEstado: '',
      usuarioID: "b60d311e-1bf2-4377-ab84-a227e308e66a",
      minDateVencimiento: moment().format(),
      spinner: false,
      hasSubmmited: false
    }
  },
  validations: {
    proveedor: { required },
    fechaEmision: { required },
    factura: { required },
    concepto: { required },
    fechaVencimiento: { required },
    pedidoProductos: { required, minLength: minLength(1) }
  },
  mounted() {
    this.consultarProveedores();
    this.buscarFacturas();
  },
  components: {
    RowProducto
  },
  computed: {
    ...mapState("facturas", ["listaProveedores"])
  },
  methods: {
    ...mapActions("facturas", ["consultarProveedores", "listaFacturas"]),
    hideModal() {
      this.resetForm();
      this.$bvModal.hide('modal-agregar-factura');
    },
    borrarProducto(id) {
      this.pedidoProductos.splice(id, 1);
      this.actualizarFormTotales();
    },
    actualizarFechaMinimaVencimiento() {
      if (moment(this.minDateVencimiento).isBefore(this.fechaEmision)) {
        this.fechaVencimiento = this.fechaEmision;
      }
      this.minDateVencimiento = moment(this.fechaEmision).format();
    },
    actualizarFormTotales() {
      /** el objeto de producto ya contiene los importes correspondientes de cada concepto, solo iteramos y sumamos */
      this.subTotal = 0;
      this.totalSurtidoConIVA = 0;
      this.iva = 0;
      this.ieps = 0;
      this.total = 0;
      this.pedidoProductos.map((item) => {
        this.subTotal += parseFloat(item.subtotal);
        this.totalSurtidoConIVA += parseFloat(item.total);
        this.iva += parseFloat(item.importeIVA);
        item.ieps ? this.ieps += parseFloat(item.ieps) : '';
        this.total += parseFloat(item.total);
      });
      // fixed 2 decimales
      this.subTotal = this.subTotal.toFixed(2);
      this.totalSurtidoConIVA = this.totalSurtidoConIVA.toFixed(2);
      this.iva = this.iva.toFixed(2);
      this.ieps = this.ieps.toFixed(2);
      this.total = this.total.toFixed(2);
    },
    async buscarFacturas() {
      api("GET", "facturas", {}, { "estados[0]": "T" })
        .then((response) => {
          this.listaFacturasGuardadas = [
            { value: null, text: "-- Seleccione una factura --" },
            ...response.map((item) => {
              return { value: item.NumFactura, text: ` ${item.FacturaID} - ${item.NumFactura}` }
            })
          ];
        })
        .catch(() => {
          this.listaFacturasGuardadas = [];
        });
    },
    async cargarFactura(numeroFactura) {
      if (!numeroFactura) {
        this.makeToast('Cargar Factura', 'Seleccione una factura', 'warning');
        return;
      }
      this.spinner = true;
      this.resetForm();
      api("GET", "facturas", {}, { NumFactura: numeroFactura, estados: ['T'] })
        .then((response) => {
          const factura = response[0];
          this.pedidoProductos = factura.factura_productos.map((item) => {
            return {
              ...item,
              producto: { codigo: item.Producto.codigo, descripcion: item.Producto.descripcion },
              precio: item.Precio,
              iva: item.Producto.iva,
              cantidad: item.CantidadPedida,
              cantidadSurtida: item.CantidadSurtida,
              total: 0,
              subTotal: 0
            }
          });
          this.facturaID = factura.FacturaID;
          this.pedidoID = factura.pedido.id;
          this.pedidoCliente = factura.pedido.cliente;
          this.pedidoProveedor = factura.pedido.proveedor;
          this.pedidoVendedor = factura.pedido.vendedor;
          this.concepto = factura.Concepto;
          this.proveedor = factura.pedido.proveedor;
          this.fechaEmision = moment(factura.FechaEmision).format("YYYY-MM-DD");
          this.fechaVencimiento = moment(factura.Vencimiento).format("YYYY-MM-DD");
          this.factura = factura.NumFactura;
          this.totalSurtidoConIVA = factura.TotalPedidoSurtidoIVA;
          this.total = factura.Total;
          this.subTotal = factura.Subtotal;
          this.iva = factura.IVA;
          this.ieps = factura.IEPS;
          this.spinner = false;
          return;
        })
        .catch((error) => {
          this.makeToast('Cargar Factura', `Error en el consumo: ${error}`, 'danger');
          this.spinner = false;
        });
    },
    async buscarPedido(numeroPedido, consecutivo) {
      if (!numeroPedido) {
        this.makeToast('Buscar Pedido', 'Capture código de pedido', 'warning');
        return;
      }
      this.spinner = true;
      this.resetForm();
      api("GET", "pedidos", {}, { codigo: numeroPedido, backorder: Boolean(consecutivo), consecutivo, cancelado: 0 })
        .then((response) => {
          if (!response.length) {
            this.makeToast('Buscar Pedido', `Pedido con código ${numeroPedido} no encontrado`, 'info');
            this.spinner = false;
            return;
          }
          const pedido = response[0];
          if (pedido.FacturaID) {
            this.makeToast('Buscar Pedido', `Pedido con código ${numeroPedido} ya se encuentra Facturado`, 'warning', 3000);
            this.spinner = false;
            return;
          }

          this.pedidoID = pedido.id;
          this.codigoPedido = pedido.codigo;
          this.pedidoCliente = pedido.cliente;
          this.pedidoClienteRS = pedido.cliente_usuario.razon_social;
          this.pedidoConsecutivo = pedido.consecutivo;
          this.pedidoProveedor = pedido.proveedor;
          this.pedidoVendedor = pedido.vendedor;
          // agregamos cantidad surtida e importe del IVA al objeto del producto
          this.pedidoProductos = pedido.pedido_productos.map((item) => {
            return { ...item, cantidadSurtida: item.cantidad, importeIVA: 0 }
          });
          this.proveedor = pedido.proveedor;
          this.concepto = pedido.concepto; //`E.C. PEDIDO ${this.pedido.codigo}`;
          this.fechaEmision = moment(pedido.fecha_confirmacion).format("YYYY-MM-DD");
          this.factura = pedido.codigo;
          this.totalSurtidoConIVA = 0;
          this.total = pedido.total;
          this.subTotal = pedido.subtotal;
          this.iva = pedido.iva;
          this.ieps = pedido.ieps;
          // 
          this.actualizarFechaMinimaVencimiento();
          this.spinner = false;
          return;
        })
        .catch((error) => {
          this.makeToast('Buscar Pedido', `Error en el consumo: ${error}`, 'danger');
          this.spinner = false;
        })
    },
    resetForm() {
      this.concepto = '';
      this.facturaModel = null,
        this.pedidoProductos = [];
      this.proveedor = null;
      this.totalSurtidoConIVA = 0;
      this.fechaEmision = null;
      this.fechaVencimiento = null;
      this.factura = '';
      this.total = 0;
      this.subTotal = 0;
      this.iva = 0;
      this.ieps = 0;
      this.SaldoActual = 0;
      this.codigoProducto = '';
      this.subCodigoProducto = '';
      this.pedidoModel = '';
      this.backorderModel = '';
      this.pedidoID = null;
      this.facturaID = null;
      this.fechaEmision = null;
      this.facturaEstado = '';
      this.minDateVencimiento = null;
      this.pedidoCliente = null;
      this.pedidoProveedor = null;
      this.pedidoVendedor = null;
      this.pedidoClienteRS = '';
    },
    async agregarProducto(codigo, subcodigo) {
      if (codigo === '') {
        this.makeToast('Agregar Producto', 'Capture un código', 'warning');
        return;
      }

      // validar captura de producto doble
      const duplicado = this.pedidoProductos.filter(item => item.producto.codigo === codigo)
      if (duplicado.length) {
        this.makeToast('Agregar Producto', 'Código de producto duplicado', 'warning');
        return;
      }

      this.spinner = true;
      api("GET", "productos", {}, { codigo: codigo, subcodigo: subcodigo, proveedor: this.pedidoProveedor, cliente: this.pedidoCliente, suspendido: 0 })
        .then((response) => {
          if (!response.productos.length) {
            this.makeToast('Buscar Producto', `Producto con código ${codigo} ${subcodigo} no encontrado`, 'info', 3000);
            this.spinner = false;
            return;
          }

          const producto = response.productos[0];

          let precio;

          switch (response.precios.precio) {
            case "1":
              precio = producto.precio.precio_1;
              break;
            case "2":
              precio = producto.precio.precio_2;
              break;
            case "3":
              precio = producto.precio.precio_3;
              break;
            case "4":
              precio = producto.precio.precio_4;
              break;
            case "5":
              precio = producto.precio.precio_5;
              break;
          }

          this.pedidoProductos.push({
            "producto": producto,
            "subtotal": 0.00,
            "total": 0.00,
            "cantidad": 0,
            "precio": precio,
            "iva": producto.iva,
            "ieps": 0,
            "comision": "1",
            "cantidadSurtida": 0,
            "importeIVA": 0.00
          })
          this.spinner = false;
          return;
        })
        .catch((error) => {
          this.makeToast('Buscar Pedido', `Error en el consumo: ${error}`, 'danger');
          this.spinner = false;
        })
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    generarConsecutivo(letra = "") {
      if (letra.length !== 1 || !/^[A-Z]$/.test(letra))
        return 'A';

      const codigoASCII = letra.charCodeAt(0);
      const siguienteCodigo = (codigoASCII - 65 + 1) % 26 + 65;
      return String.fromCharCode(siguienteCodigo);
    },
    guardarFactura() {
      this.facturaEstado = 'N';
      const method = "POST";
      this.facturaID = null;
      // generar pedido si tenemos cantidades surtidas menores
      const productosBackorder = this.pedidoProductos.filter(item => item.cantidadSurtida < item.cantidad);
      if (productosBackorder.length > 0) {
        // calculos previos 
        const fechaConfirmacion = moment().format("YYYY/MM/DD HH:mm:ss");
        const consecutivo = this.generarConsecutivo(this.pedidoConsecutivo);
        let total = 0;
        let subtotal = 0;
        let iva = 0;
        let ieps = 0;
        let productos = [];
        // productos
        productosBackorder.map(item => {
          let itemCantidad = item.cantidad - item.cantidadSurtida;
          let itemSubtotal = itemCantidad * item.precio;
          let itemTotalIVA = itemSubtotal * (item.iva || 0.16);
          let itemTotal = itemSubtotal + itemTotalIVA;
          let producto = {
            "producto_id": item.producto_id,
            "cantidad": itemCantidad,
            "precio": item.precio,
            "iva": item.iva,
            "ieps": item.ieps,
            "comision": item.comision,
            "subtotal": itemSubtotal,
            "totaliva": itemTotalIVA,
            "total": itemTotal
          };
          productos.push(producto);
          // acumulados para pedido
          subtotal += itemSubtotal;
          iva += itemTotalIVA;
          total += itemTotal;
        });

        const objPedido = {
          "pedido": {
            "codigo": this.codigoPedido,
            "cliente": this.pedidoCliente,
            "vendedor": this.pedidoVendedor,
            "proveedor": this.pedidoProveedor,
            "total": total.toFixed(2),
            "subtotal": subtotal.toFixed(2),
            "iva": iva.toFixed(2),
            "ieps": ieps.toFixed(2),
            "confirmacion_enviada": 0,
            "fecha_confirmacion": fechaConfirmacion,
            "comentarios": "BACKORDER de prueba con endpoint nuevo.",
            "backorder": 1,
            "consecutivo": consecutivo
          },
          "productos": productos
        };

        api("POST", "pedidos", objPedido)
          .then((response) => {
            if (!response || response === null) {
              this.makeToast('Generar Backorder', `Error al generar backorder: ${response}`, 'danger', 10000);
              this.spinner = false;
              this.hasSubmmited = false;
              return;
            }

            this.makeToast('Generar Backorder', 'Backorder generado con éxito', 'success', 3000);
            // generamos factura
            this.onSubmit("POST");
          })
          .catch((error) => {
            this.makeToast('Generar Backorder', `Error al generar backorder - ${error}: ${error.response.data.name} `, 'danger', 10000);
            this.spinner = false;
            this.hasSubmmited = false;
          });
      } else {
        // generamos factura
        this.onSubmit(method);
      }
    },
    guardarFacturaTemporal() {
      this.facturaEstado = 'T';
      // FacturaID es nulo cuando se intenta guardar directamente el Pedido, caso contrario es una factura existente. 
      const method = (this.facturaID === null) ? "POST" : "PUT";
      this.onSubmit(method);
    },
    async onSubmit(method = "POST") {
      this.spinner = true;
      this.hasSubmmited = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      }
      else {
        this.submitStatus = 'PENDING'

        const productos = [];

        productos.push(...this.pedidoProductos.map((item) => {
          return {
            ProductoID: item.ProductoID || item.producto_id || item.producto.producto_id,
            CantidadPedida: item.cantidad,
            CantidadSurtida: item.cantidadSurtida,
            Precio: item.precio
          };
        }))

        const objFactura = {
          "factura": {
            "FacturaID": this.facturaID,
            "FechaEmision": this.fechaEmision,
            "NumFactura": this.factura,
            "Concepto": this.concepto,
            "Vencimiento": this.fechaVencimiento,
            "TotalPedidoSurtidoIVA": this.totalSurtidoConIVA,
            "Subtotal": this.subTotal,
            "IVA": this.iva,
            "Total": this.total,
            "IEPS": this.ieps,
            "SaldoActual": this.SaldoActual,
            "Cheque": 0,
            "Estado": this.facturaEstado
          },
          "productos": productos,
          "usuarioID": "b60d311e-1bf2-4377-ab84-a227e308e66a",
          "pedidoID": this.pedidoID
        };

        api(method, "facturas", objFactura)
          .then(() => {
            this.makeToast('Agregar Factura', 'Factura guardada con éxito', 'success', 3000);
            // success
            this.buscarFacturas(); // recarga el select de facturas
            this.hideModal();
          })
          .catch((error) => {
            this.makeToast('Agregar Factura', `Error al guardar la factura - ${error.response.data.msg || ''} `, 'danger', 10000);
          })
          .finally(() => {
            this.spinner = false;
            this.hasSubmmited = false;
          });
      }
    }
  },
  filters: {
    moneda: function (number) {
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'PMX' }).format(number);
    }
  }
}
</script>

<style>
.modal-xl {
  min-width: 95vw;
}
</style>