<template>
  <b-row>
    <b-col cols="1" class="text-center p-0">
      <b-button-group size="sm">
        <!--<b-button variant="outline-success">Editar</b-button>-->
        <b-button variant="outline-danger" @click="borrarProducto">Borrar</b-button>
      </b-button-group>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-form-input size="sm" v-model="producto.producto.codigo" readonly>
      </b-form-input>
    </b-col>
    <b-col cols="4" class="text-center p-0">
      <b-form-input size="sm" v-model="producto.producto.descripcion" readonly>
      </b-form-input>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-input-group prepend="$" size="sm" class="ms-1">
        <b-form-input v-model="producto.precio" @update="actualizarImportes"></b-form-input>
      </b-input-group>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-form-input v-model="producto.cantidad" size="sm" readonly></b-form-input>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-form-input size="sm" v-model="producto.cantidadSurtida" min="0" @update="actualizarImportes">
      </b-form-input>
    </b-col>
    <b-col cols="1" class="text-center p-0">
      <b-input-group prepend="$" size="sm">
        <b-form-input v-model="producto.subtotal" @update="$emit('actualizarForm')"></b-form-input>
      </b-input-group>
    </b-col>
    <b-col cols="2" class="text-center p-0">
      <b-input-group prepend="$" size="sm">
        <b-form-input v-model="producto.total" @update="$emit('actualizarForm')"></b-form-input>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'RowProducto',
  props: {
    producto: {
      type: Object
    },
    id: {

    }
  },
  mounted() {
    this.actualizarImportes();
  },
  methods: {
    actualizarImportes() {
      let subtotal = this.producto.cantidadSurtida * this.producto.precio;
      if (!this.producto.iva) this.producto.iva = 0;
      let totalIVA = subtotal * this.producto.iva;
      let total = subtotal + totalIVA;
      // fixed 2 decimales
      this.producto.importeIVA = totalIVA.toFixed(2);
      this.producto.subtotal = subtotal.toFixed(2);
      this.producto.total = total.toFixed(2);
      this.$emit("actualizarForm");
    },
    borrarProducto() {
      this.$emit("borrarProductoEvent", this.id);
    }
  },
}
</script>