<template>
  <div id="Facturas">
    <AgregarFactura v-if="soyAdmin" />
    <BarraBusqueda ref="barraBusqueda" @estadoCuentaPDF="data => estadoCuenta(data, 1)" @estadoCuentaExcel="data => estadoCuenta(data, 2)" />
    <ListaFacturas @actualizar="actualizarLista" />
    <EstadoCuentaPDF ref="EstadoCuenta" />
  </div>
</template>

<script>
import AgregarFactura from "./AgregarFactura.vue";
import BarraBusqueda from "./BarraBusqueda.vue";
import ListaFacturas from "./ListaFacturas.vue"
import EstadoCuentaPDF from './EstadoCuentaPDF.vue';
export default {
  name: "FacturasView",
  data() {
    return {
      soyAdmin: false,
      usuario: '',
    }
  },
  mounted() {
    this.soyAdmin = sessionStorage.getItem('admin') === 'true';
  },
  components: { AgregarFactura, BarraBusqueda, ListaFacturas, EstadoCuentaPDF },
  methods: {
    actualizarLista() {
      this.$refs.barraBusqueda.consultarFacturas();
    },
    estadoCuenta(data, option) {
      if (option === 1) {
        this.$refs['EstadoCuenta'].printPDF(data);
      }
      else {
        this.$refs['EstadoCuenta'].printExcel(data);
      }
    },
  },
}
</script>