<template>
  <div>
    <b-container fluid>
      <b-row align-h="start">
        <b-col cols="3" class="">
          <b-form-select v-model="cliente" v-if="soyAdmin" :options="listaClientes" size="sm" class="m-1" />
        </b-col>
        <b-col cols="3" class="">
          <b-form-select v-model="proveedor" :options="listaProveedores" size="sm" class="m-1" />
        </b-col>
        <b-col cols="6" class="d-flex justify-content-left">
          <b-button variant="success" size="sm" class="m-1" v-if="soyAdmin" v-b-modal.modal-agregar-factura>Agregar
            Factura
          </b-button>
          <b-button variant="primary" size="sm" class="m-1" @click="estadoCuenta(1)">Estado de cuenta PDF</b-button>
          <b-button variant="primary" size="sm" class="m-1" @click="estadoCuenta(2)">Estado de cuenta Excel</b-button>
        </b-col>
      </b-row>
      <b-row align-h="start">
        <b-col cols="3" class="">
          <b-form-input v-model="pedido" size="sm" class="m-1" placeholder="Pedido" />
        </b-col>

        <b-col cols="3" class="">
          <b-form-input v-model="NumFactura" size="sm" class="m-1" placeholder="Factura" />
        </b-col>

        <b-col cols="3" class="">
          <b-form-radio-group size="sm" class="m-1" v-model="facturaEstado" :options="facturaEstadoOptions"
            name="radio-inline" />
        </b-col>

        <b-col cols="2">
          <b-button variant="primary" size="sm" class="m-1" id="btnBuscar" @click="consultarFacturas">
            Buscar
          </b-button>

          <b-button variant="primary" size="sm" class="m-1" @click="resetForm">
            Reset
          </b-button>
        </b-col>

        <b-col cols="1">
          <div v-if="isBusy">
            <div class="clearfix">
              <b-icon icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { axiosInstance } from '../../../utils/axiosInstance';

export default {
  data() {
    return {
      facturaEstado: '',
      facturaEstadoOptions: [
        { text: 'Todas', value: '' },
        { text: 'Pagadas', value: 'P' },
        { text: 'No Pagadas', value: 'N' }
      ],
      cliente: null,
      proveedor: null,
      NumFactura: '',
      pedido: '',
      soyAdmin: false,
      usuario: '',
    }
  },
  emits: ['estadoCuentaPDF', 'estadoCuentaExcel'],
  mounted() {
    this.soyAdmin = sessionStorage.getItem('admin') === 'true';
    this.usuario = sessionStorage.getItem('usuario_id');
    this.consultarClientes();
    this.soyAdmin ? this.consultarProveedores() : this.getProveedoresCliente(this.usuario);

    if (!this.soyAdmin) {
      this.cliente = this.usuario;
    }
  },
  computed: {
    ...mapState("facturas", ["listaClientes", "listaProveedores", "isBusy", "listaFacturas"])
  },
  methods: {
    ...mapActions("facturas", ['limpiarDatos', "consultarClientes", "consultarProveedores", "consultarFacturasAction", "getProveedoresCliente"]),
    resetForm() {
      this.facturaEstado = '';
      this.cliente = null;
      this.proveedor = null;
      this.pedido = '';
      this.factura = '';
      this.limpiarDatos();
    },
    async consultarFacturas() {
      let payload = {
        body: {},
        params: {
          "cliente": this.soyAdmin ? this.cliente : this.usuario,
          "proveedor": this.proveedor,
          "pedido": this.pedido,
          "NumFactura": this.NumFactura,
        }
      };
      if (this.facturaEstado !== '') {
        payload.params["estados[0]"] = this.facturaEstado;
      } else {
        payload.params["estados[0]"] = 'N';
        payload.params["estados[1]"] = 'P';
      }
      await this.consultarFacturasAction(payload);
    },
    async estadoCuenta(option) {
      if (!this.cliente) {
        this.makeToast('Estado de cuenta', 'Elija un cliente para obtener su estado de cuenta', 'warning', 3000);
        return;
      }

      try {
        const { data } = await axiosInstance.get(`facturas/estado-cuenta/${this.cliente}`, {
          params: {
            Estatus: this.facturaEstado,
            ProveedorID: this.proveedor ? this.proveedor : ''
          }
        });

        if (option === 1) {
          this.$emit('estadoCuentaPDF', data);
        }
        else {
          this.$emit('estadoCuentaExcel', data);
        }
      } catch (error) {
        this.makeToast('Estado de cuenta', 'Hubo un error al obtener el estado de cuenta del cliente.', 'danger', 3000);
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
  }
}
</script>